import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled, { ThemeProvider } from 'styled-components';
import themeModify, { checkContrast, Opacity } from '../../interface';
import Input from '../../interface/input';
import Button from '../../interface/button';
import Portal from '@bloc/portal';

import bg from './assets/rr_pattern@2x.png';

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  overflow: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: flex;
    align-items: stretch;
    width: 760px;
    height: auto;
    border-radius: 4px;
  }

  & .login-box__image {
    display: none;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      display: block;
      background-image: url(${bg});
      background-size: cover;
      width: 300px;
    }
  }

  & .login-box__form {
    box-sizing: border-box;
    padding: 40px;

    @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
      overflow: auto;
      width: 460px;
    }

    & .input,
    & .button {
      width: 100%;
    }

    & .input {
      margin: 0.5em 0;
    }

    & .error {
      margin: 0;
      height: 1em;
      color: ${({ theme }) => theme.palette.error.main};
      text-align: center;
      font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    }

    & .button {
      margin: 1.5em 0 0.5em 0;

      &.login-box__guest-access {
        margin: 0.5em 0 1.5em 0;
      }
    }

    & h1 {
      margin: 0 0 0.5em 0;
      line-height: 1.05em;
      font-size: 30px;
      font-family: ${({ theme }) => theme.typography.h1.fontFamily};
      color: ${({ theme }) => checkContrast(theme.palette.primary.dark, theme.palette.common.white)};
      font-weight: 500;
    }

    & p {
      margin: 0;
      font-size: 16px;
      line-height: 1.2em;
      font-family: ${({ theme }) => theme.typography.subtitle1.fontFamily};
      color: ${({ theme }) => checkContrast(theme.palette.grey[700], theme.palette.common.white)};
      margin-bottom: 1.6em;
    }

    & .login-box__text-divider {
      width: 100%;
      display: flex;
      align-items: center;
      line-height: 1em;

      & span {
        padding: 0.7em;
        color: ${({ theme }) => checkContrast(theme.palette.grey[700], theme.palette.common.white)};
        font-size: 20px;
        font-family: ${({ theme }) => theme.typography.subtitle1.fontFamily};
      }

      &::before,
      &::after {
        content: '';
        flex-grow: 1;
        height: 2px;
        background-color: ${({ theme }) => theme.palette.grey[500]};
      }
    }

    & .login-box__extra-options {
      display: flex;
      justify-content: space-between;
      margin: 0.5em 0 0 0;

      & .login-box__request-access,
      & .login-box__forgot-password {
        font-size: 15px;
        margin: 0;
        width: calc(50% - 0.5em);

        &:hover,
        &:focus {
          background-color: ${({ theme }) => Opacity(theme.palette.primary.main, 5)};
        }
      }
    }
  }
`;

/**
 *
 */
export default function LoginDialog({
  theme,
  className,
  variant,
  error,
  guest,
  onSubmit,
  onRequest,
  onForgot,
  onGuest,
  parent,
  active,
  title,
  message,
  ...props
}) {
  const _theme = useMemo(() => themeModify(theme), [theme]);
  const [classList, setClassList] = useState(['login-box']);

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  // set up classes/variants
  useEffect(() => {
    const _classList = ['login-box'];
    for (const c of className.split(' ')) {
      _classList.push(c);
    }

    for (const v of variant.split(' ')) {
      if (v) _classList.push(`variant--${v}`);
    }

    setClassList(_classList);
  }, [className, variant]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ username, password });
  };

  return (
    active && (
      <Portal parent={parent.current} className="login-portal dialog-box">
        <ThemeProvider theme={_theme}>
          <StyledDiv classList={classList}>
            <div className="login-box__image"></div>
            <form className="login-box__form" onSubmit={handleSubmit}>
              <h1>{title}</h1>
              {message && <p>{message}</p>}
              <p>
                Please log in with your registration details. If you require access, simply submit an access request and
                we'll be in touch shortly.
              </p>
              <Input label="Email" placeholder="name@email.com" onChange={(e) => setUsername(e.target.value)} />
              <Input
                label="Password"
                placeholder="******"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <p className="error">{error}</p>
              <Button onClick={handleSubmit}>Log in</Button>
              <div className="login-box__text-divider">
                <span>or</span>
              </div>
              {guest && (
                <Button variant="secondary" onClick={onGuest} className="login-box__guest-access">
                  Continue as guest
                </Button>
              )}
              <div className="login-box__extra-options">
                <Button onClick={onRequest} className="login-box__request-access" variant="tertiary  tight">
                  Request Access
                </Button>
                <Button onClick={onForgot} className="login-box__forgot-password" variant="tertiary tight">
                  Forgot Password?
                </Button>
              </div>
            </form>
          </StyledDiv>
        </ThemeProvider>
      </Portal>
    )
  );
}

LoginDialog.propTypes = {
  /** theme object - pass in a modification to theme */
  theme: PropTypes.object,
  /** className string - classes to be added */
  className: PropTypes.string,
  /** variant string - variants to be added */
  variant: PropTypes.string,
  /** error string - error message */
  error: PropTypes.string,
  /** guest boolean - display guest button */
  guest: PropTypes.bool,
  /** onSubmit function - callback function for form submit */
  onSubmit: PropTypes.func,
  /** onRequest function - callback function for form submit */
  onRequest: PropTypes.func,
  /** onForgot function - callback function for form submit */
  onForgot: PropTypes.func,
  /** onGuest function - callback function for guest click */
  onGuest: PropTypes.func,
  /** title string - title to show in header */
  title: PropTypes.string,
  /** message string - msg to show in modal */
  message: PropTypes.string,
};

LoginDialog.defaultProps = {
  theme: {},
  className: '',
  variant: '',
  onSubmit: () => {},
  onRequest: () => {},
  onForgot: () => {},
  onGuest: () => {},
};
