import { useMemo } from 'react';

export default function Icon({ type, ...props }) {
  const icon = useMemo(() => {
    switch (type) {
      case 'checkbox':
        return (
          <svg {...props} viewBox="0 0 18 18">
            <path d="M0 0v18h18V0H0zm1 1h16v16H1V1z" />
          </svg>
        );
      case 'checkbox-checked':
        return (
          <svg {...props} viewBox="0 0 18 18">
            <path d="M0 0v18h18V0H0zm1 1h16v16H1V1z" />
            <path d="M3 9.485l1.414-1.414L7.242 10.9l7.071-7.07 1.415 1.414-8.486 8.485z" />
          </svg>
        );
      default:
        return (
          <svg {...props} height="24" viewBox="0 0 24 24" width="24">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
          </svg>
        );
    }
  }, [props, type]);

  return icon;
}
