import { useMemo } from 'react';

function Icon({ type, ...props }) {
  return useMemo(() => {
    switch (type) {
      case 'arrow_right':
        return (
          <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M15,5l-1.41,1.41L18.17,11H2V13h16.17l-4.59,4.59L15,19l7-7L15,5z" />
          </svg>
        );
      case 'complete':
        return (
          <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
          </svg>
        );
      default:
        return (
          <svg {...props} height="24" viewBox="0 0 24 24" width="24">
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
          </svg>
        );
    }
  }, [props, type]);
}

export default Icon;
