import React from 'react';
import PropTypes from 'prop-types';

export default function ImportSVG({ src, ...props }) {
  const [innerHTML, setInnerHTML] = React.useState(null);

  React.useEffect(() => {
    if (!src) return;

    fetch(src)
      .then((r) => r.text())
      .then((text) => {
        if (/^<svg /.test(text)) {
          setInnerHTML(text);
        } else {
          throw new Error('suspected code injection');
        }
      })
      .catch((e) => {
        console.warn(`import_svg: ${e}`);
        setInnerHTML(
          `<svg height="24" viewBox="0 0 24 24" width="24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" /></svg>`,
        );
      });
  }, [src]);

  return <span dangerouslySetInnerHTML={{ __html: innerHTML }}></span>;
}

ImportSVG.propTypes = {
  src: PropTypes.string,
};

ImportSVG.defaultProps = {
  src: `<svg height="24" viewBox="0 0 24 24" width="24"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" /></svg>`,
};
