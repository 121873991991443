import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled, { ThemeProvider } from 'styled-components';
import themeModify, { checkContrast } from '../../interface';
import Input from '../../interface/input';
import Button from '../../interface/button';

import Portal from '@bloc/portal';

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  overflow: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    border-radius: 4px;
    width: 460px;
    height: auto;
  }

  & .input,
  & .button,
  & .dropdown {
    width: 100%;
  }

  & .input,
  & .dropdown {
    margin: 0.5em 0;
  }

  & .error {
    margin: 0;
    height: 1em;
    color: ${({ theme }) => theme.palette.error.main};
    text-align: center;
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
  }

  & .button {
    margin: 1.5em 0 0 0;
  }

  & h1 {
    margin: 0 0 0.5em 0;
    line-height: 1.05em;
    font-size: 30px;
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    color: ${({ theme }) => checkContrast(theme.palette.primary.dark, theme.palette.common.white)};
    font-weight: 500;
  }

  & p {
    margin: 0;
    font-size: 16px;
    line-height: 1.2em;
    font-family: ${({ theme }) => theme.typography.subtitle1.fontFamily};
    color: ${({ theme }) => checkContrast(theme.palette.grey[700], theme.palette.common.white)};
    margin-bottom: 1.6em;
  }
`;

/**
 *
 */
export default function AuthDialog({ theme, className, variant, error, onSubmit, parent, active, ...props }) {
  const _theme = useMemo(() => themeModify(theme), [theme]);
  const [classList, setClassList] = useState(['guest-box']);

  const [auth, setAuth] = useState();

  // set up classes/variants
  useEffect(() => {
    const _classList = ['guest-box'];
    for (const c of className.split(' ')) {
      _classList.push(c);
    }

    for (const v of variant.split(' ')) {
      if (v) _classList.push(`variant--${v}`);
    }

    setClassList(_classList);
  }, [className, variant]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ auth });
  };

  return (
    active && (
      <Portal parent={parent.current} className="auth-portal dialog-box">
        <ThemeProvider theme={_theme}>
          <StyledDiv classList={classList}>
            <form onSubmit={handleSubmit}>
              <h1>We need your log in code</h1>
              <p>Please enter the email code we sent you below.</p>
              <Input
                label="6 Digit Code"
                placeholder="******"
                type="password"
                maxLength="6"
                onChange={(e) => setAuth(e.target.value)}
              />
              <p className="error">{error}</p>
              <Button onClick={handleSubmit}>Submit</Button>
            </form>
          </StyledDiv>
        </ThemeProvider>
      </Portal>
    )
  );
}

AuthDialog.propTypes = {
  /** theme object - pass in a modification to theme */
  theme: PropTypes.object,
  /** className string - classes to be added */
  className: PropTypes.string,
  /** variant string - variants to be added */
  variant: PropTypes.string,
  /** error string - error message */
  error: PropTypes.string,
  /** onSubmit function - callback function for form submit */
  onSubmit: PropTypes.func,
};

AuthDialog.defaultProps = {
  theme: {},
  className: '',
  variant: '',
  onSubmit: () => {},
};
