import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled, { ThemeProvider } from 'styled-components';
import themeModify, { checkContrast, LightenDarkenColor } from './';

const StyledButton = styled.button`
  padding: 0.5em 2em;
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.button.fontSize};
  font-family: ${({ theme }) => theme.typography.button.fontFamily};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  line-height: ${({ theme }) => theme.typography.button.lineHeight};
  letter-spacing: ${({ theme }) => theme.typography.button.letterSpacing};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 2px solid ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => checkContrast(theme.palette.primary.contrastText, theme.palette.primary.main)};
  fill: currentColor;

  & > .button__body {
    pointer-events: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${({ theme }) => theme.typography.button.lineHeight};

    & .button__icon {
      line-height: 0;

      & svg {
        height: 1.2em;
        width: 1.2em;
        margin-right: 0.5em;
      }
    }
  }

  &:hover {
    background-color: ${({ theme }) => LightenDarkenColor(theme.palette.primary.main, -8)};
    border-color: ${({ theme }) => LightenDarkenColor(theme.palette.primary.main, -8)};
  }

  &:active {
    background-color: ${({ theme }) => LightenDarkenColor(theme.palette.primary.main, -25)};
    border-color: ${({ theme }) => LightenDarkenColor(theme.palette.primary.main, -25)};
  }

  &.variant--tight {
    padding: 0.25em 1em;
  }

  &.variant--secondary {
    background-color: ${({ theme }) => theme.palette.common.white};
    border-color: ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      background-color: ${({ theme }) => theme.palette.grey[100]};
    }

    &:active {
      background-color: ${({ theme }) => theme.palette.grey[400]};
    }
  }

  &.variant--tertiary {
    background-color: ${({ theme }) => theme.palette.common.white};
    border-color: ${({ theme }) => theme.palette.grey[500]};
    border-radius: 5rem;
    color: ${({ theme }) => theme.palette.primary.dark};

    &:hover {
      background-color: ${({ theme }) => theme.palette.grey[100]};
    }
  }

  &.variant--quaternary {
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.palette.primary.main};

    &:hover {
      color: ${({ theme }) => theme.palette.primary.dark};
    }
  }

  &.variant--icon-right {
    & > .button__body {
      flex-direction: row-reverse;

      & .button__icon {
        & svg {
          margin-right: initial;
          margin-left: 0.5em;
        }
      }
    }
  }

  &.internal--just-icon {
    & > .button__body {
      & .button__icon {
        & svg {
          margin-right: 0;
        }
      }
    }
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.palette.grey.disabled};
    border-color: ${({ theme }) => theme.palette.grey.disabled};
    color: ${({ theme }) => checkContrast(theme.palette.common.black, theme.palette.grey.disabled)};
    pointer-events: none;

    &.variant--secondary,
    &.variant--tertiary,
    &.variant--quaternary {
      background-color: ${({ theme }) => theme.palette.grey[200]};
      color: ${({ theme }) => theme.palette.grey[700]};
    }
  }
`;

/**
 * TO DO:
 * -[x] add a variant to move the icon to the right of the button
 * -[x] sort out the spacing on the icon - sort of?
 * -[x] convert pixel measurements to em
 * -[x] convert all functions to be on the same line and to take theme rather than props
 * -[x] quaternary button need to take icon as an arg rather than +
 */

export default function Button({ theme, className, variant, icon, ...props }) {
  const _theme = useMemo(() => themeModify(theme), [theme]);
  const [classList, setClassList] = useState(['button']);

  // set up button classes
  useEffect(() => {
    const _classList = ['button'];
    for (const c of className.split(' ')) {
      _classList.push(c);
    }

    for (const v of variant.split(' ')) {
      if (v) _classList.push(`variant--${v}`);
    }

    if (icon) _classList.push('internal--has-icon');
    if (icon && !props.children) _classList.push('internal--just-icon');

    setClassList(_classList);
  }, [className, icon, props.children, variant]);

  return (
    <ThemeProvider theme={_theme}>
      <StyledButton {...props} className={classList}>
        <div className="button__body">
          {icon && <div className="button__icon">{icon}</div>}
          <span>{props.children}</span>
        </div>
      </StyledButton>
    </ThemeProvider>
  );
}

Button.propTypes = {
  /** theme object - changes to default theme */
  theme: PropTypes.func,
  /** className string - classes to be added to button */
  className: PropTypes.string,
  /** variant string - variant of to be added to button, invokes preset classes */
  variant: PropTypes.string,
  /** icon element - Icon to appear inside button */
  icon: PropTypes.element,
};

Button.defaultProps = {
  theme: {},
  className: '',
  variant: '',
};
