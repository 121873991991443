import { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import 'wicg-inert';

import styled, { ThemeProvider } from 'styled-components';
import themeModify, { Opacity, LightenDarkenColor } from '../../interface';
import Button from '../../interface/button';

import Icon from './icons';

import Portal from '@bloc/portal';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => Opacity(LightenDarkenColor(theme.palette.common.black, 20), 30)};
  backdrop-filter: blur(1px);
  opacity: 0;
  transition-property: all;
  transition-duration: ${({ theme }) => theme.transitions.duration.shortest};
  transition-timing-function: ${({ theme }) => theme.transitions.easing.easeInOut};
  transition-delay: ${({ theme }) => theme.transitions.duration.short};
  display: flex;
  align-items: center;
  justify-content: center;

  & .modal-content {
    transform: translateY(100px);
    transition-property: all;
    transition-duration: ${({ theme }) => theme.transitions.duration.short};
    transition-timing-function: ${({ theme }) => theme.transitions.easing.easeInOut};
    opacity: 0;
  }

  &.active {
    transition-duration: ${({ theme }) => theme.transitions.duration.short};
    transition-delay: 0ms;
    opacity: 1;

    & .modal-content {
      transform: translateY(0);
      opacity: 1;
      transition-delay: ${({ theme }) => theme.transitions.duration.short};
      transition-duration: ${({ theme }) => theme.transitions.duration.complex};
    }
  }
`;

const Content = styled.div`
  position: relative;
  padding: 2em;
  box-sizing: border-box;
  min-height: 50px;
  min-width: 50px;
  max-height: 80%;
  max-width: 380px;
  box-shadow: ${({ theme }) => theme.shadows[2]};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px;
  text-align: center;

  & .modal-content__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;

    & > svg {
      height: 3em;
      width: 3em;
      margin-bottom: 2em;
      fill: ${({ theme }) => theme.palette.secondary.main};
    }
  }

  & .modal-content__heading {
    font-size: 20px;
    color: ${({ theme }) => theme.palette.primary.dark};
    font-weight: 500;
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    margin: 0;
  }

  & .modal-content__body {
    color: ${({ theme }) => theme.palette.grey[700]};
    font-family: ${({ theme }) => theme.typography.body1.fontFamily};
    margin: 1em 1em 3em 1em;
  }

  & .button {
    width: 100%;
  }
`;

export default function Modal({ className, variant, open, header, buttonText, onClose, locked, theme, ...props }) {
  const _theme = useMemo(() => themeModify(theme), [theme]);
  const [active, setActive] = useState(false);
  const backdrop = useRef(null);

  useEffect(() => {
    const { current } = backdrop;

    const transitionEnd = () => setActive(open);

    const keyHandler = (e) => !locked && [27].indexOf(e.which) >= 0 && onClose();

    const clickHandler = (e) => !locked && e.target === current && onClose();

    if (current) {
      current.addEventListener('transitionend', transitionEnd);
      current.addEventListener('click', clickHandler);
      window.addEventListener('keyup', keyHandler);
    }

    if (open) {
      window.setTimeout(() => {
        document.activeElement.blur();
        setActive(open);
        document.querySelector('#root').setAttribute('inert', 'true');
      }, 10);
    }

    return () => {
      if (current) {
        current.removeEventListener('transitionend', transitionEnd);
        current.removeEventListener('click', clickHandler);
      }

      document.querySelector('#root').removeAttribute('inert');
      window.removeEventListener('keyup', keyHandler);
    };
  }, [open, locked, onClose]);

  return (
    <ThemeProvider theme={_theme}>
      {(open || active) && (
        <Portal className="modal-portal">
          <Backdrop ref={backdrop} className={active && open && 'active'}>
            <Content className="modal-content">
              <div className="modal-content__icon">
                <Icon type="complete" />
              </div>
              <h1 className="modal-content__heading">{header}</h1>
              <div className="modal-content__body">{props.children}</div>
              <Button onClick={onClose}>{buttonText}</Button>
            </Content>
          </Backdrop>
        </Portal>
      )}
    </ThemeProvider>
  );
}

Modal.propTypes = {
  /** theme object - pass in a modification to theme */
  theme: PropTypes.object,
  /** className string - classes to be added */
  className: PropTypes.string,
  /** variant string - variants to be added */
  variant: PropTypes.string,
  /** onClose function - callback function for form submit */
  onClose: PropTypes.func,
  /** header string - text to appear as a header */
  header: PropTypes.string,
  /** buttonText string - text to appear as in button */
  buttonText: PropTypes.string,
};

Modal.defaultProps = {
  theme: {},
  className: '',
  variant: '',
  buttonText: 'Dismiss',
  onClose: () => {},
};
