import { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled, { ThemeProvider } from 'styled-components';
import themeModify, { Opacity } from './';

import Icons from './checkbox.icons';

const StyledLabel = styled.label`
  display: inline-block;

  & .checkbox__container {
    display: flex;
    font-family: ${({ theme }) => theme.typography.body1.fontFamily};
    color: ${({ theme }) => theme.palette.grey[700]};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
      font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    }

    p {
      margin: 0;
      font-family: ${({ theme }) => theme.typography.body1.fontFamily};
    }

    & .checkbox__icon {
      fill: ${({ theme }) => theme.palette.grey[500]};
      margin-right: 1em;
      line-height: 0;

      & > svg {
        cursor: pointer;
        height: 1.2em;
        width: 1.2em;

        &.checkbox__icon--checked {
          display: none;
        }
      }
    }
  }

  & .checkbox__input {
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    white-space: nowrap;

    &[disabled] {
      & + .checkbox__container {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    &:checked {
      & + .checkbox__container {
        & .checkbox__icon {
          fill: ${({ theme }) => theme.palette.primary.main};

          & .checkbox__icon--empty {
            display: none;
          }

          & .checkbox__icon--checked {
            display: block;
          }
        }
      }
    }

    &:focus-visible {
      & + .checkbox__container {
        & .checkbox__icon {
          fill: ${({ theme }) => theme.palette.primary.main};

          & > svg {
            box-shadow: 0 0 4px 2px ${({ theme }) => Opacity(theme.palette.primary.main, 45)};
          }
        }
      }
    }
  }

  &.variant--border {
    & .checkbox__container {
      padding: 0.8em 1em;
      background-color: ${({ theme }) => theme.palette.grey[100]};
      border: 1px solid ${({ theme }) => theme.palette.grey[500]};
      border-radius: 4px;
    }
  }

  &.variant--button {
    & .checkbox__container {
      padding: 0.8em 1em;
      background-color: ${({ theme }) => theme.palette.grey[100]};
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.palette.grey[200]};
        border-color: ${({ theme }) => theme.palette.grey[500]};
      }
    }

    & .checkbox__input:focus-visible {
      & + .checkbox__container {
        border-color: ${({ theme }) => theme.palette.primary.main};
        box-shadow: 0 0 5px ${({ theme }) => Opacity(theme.palette.primary.main, 35)},
          inset 0 0 0 1px ${({ theme }) => theme.palette.primary.main};

        & .checkbox__icon {
          & > svg {
            box-shadow: none;
          }
        }
      }
    }
  }

  &.variant--center {
    & .checkbox__container {
      align-items: center;
    }
  }

  &.internal--no-body {
    & .checkbox__container {
      & .checkbox__icon {
        margin-right: 0;
      }
    }
  }
`;

/**
 * TO DO:
 * -[x] add a variant to move the icon to the right of the button
 * -[x] sort out the spacing on the icon - sort of?
 * -[x] convert pixel measurements to em
 * -[x] convert all functions to be on the same line and to take theme rather than props
 * -[x] quaternary button need to take icon as an arg rather than +
 */

export default function Checkbox({ theme, className, variant, checked, children, ...props }) {
  const _theme = useMemo(() => themeModify(theme), [theme]);
  const [classList, setClassList] = useState(['checkbox']);

  // set up button classes
  useEffect(() => {
    const _classList = ['checkbox'];
    for (const c of className.split(' ')) {
      _classList.push(c);
    }

    for (const v of variant.split(' ')) {
      if (v) _classList.push(`variant--${v}`);
    }

    if (!children) _classList.push('internal--no-body');

    setClassList(_classList);
  }, [className, children, variant]);

  return (
    <ThemeProvider theme={_theme}>
      <StyledLabel className={classList}>
        <input {...props} checked={checked} className="checkbox__input" type="checkbox" />
        <div className="checkbox__container">
          <div className="checkbox__icon">
            <Icons className="checkbox__icon--empty" type="checkbox" />
            <Icons className="checkbox__icon--checked" type="checkbox-checked" />
          </div>
          <div className="checkbox__body">
            <span>{children}</span>
          </div>
        </div>
      </StyledLabel>
    </ThemeProvider>
  );
}

Checkbox.propTypes = {
  /** theme object - changes to default theme */
  theme: PropTypes.func,
  /** className string - classes to be added to button */
  className: PropTypes.string,
  /** variant string - variant of to be added to button, invokes preset classes */
  variant: PropTypes.string,
};

Checkbox.defaultProps = {
  theme: {},
  className: '',
  variant: '',
};
