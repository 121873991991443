import { createGlobalStyle } from 'styled-components';

import RRPioneerBoldWoff from './fonts/RRPioneer-Bold.woff';
import RRPioneerBoldWoff2 from './fonts/RRPioneer-Bold.woff2';

import RRPioneerBoldItalicWoff from './fonts/RRPioneer-BoldItalic.woff';
import RRPioneerBoldItalicWoff2 from './fonts/RRPioneer-BoldItalic.woff2';

import RRPioneerItalicWoff from './fonts/RRPioneer-Italic.woff';
import RRPioneerItalicWoff2 from './fonts/RRPioneer-Italic.woff2';

import RRPioneerLightCondensedWoff from './fonts/RRPioneer-LightCondensed.woff';
import RRPioneerLightCondensedWoff2 from './fonts/RRPioneer-LightCondensed.woff2';

import RRPioneerMediumWoff from './fonts/RRPioneer-Medium.woff';
import RRPioneerMediumWoff2 from './fonts/RRPioneer-Medium.woff2';

import RRPioneerMediumItalicWoff from './fonts/RRPioneer-MediumItalic.woff';
import RRPioneerMediumItalicWoff2 from './fonts/RRPioneer-MediumItalic.woff2';

import RRPioneerRegularWoff from './fonts/RRPioneer-Regular.woff';
import RRPioneerRegularWoff2 from './fonts/RRPioneer-Regular.woff2';

import RRPioneerUltraLightCondensedWoff from './fonts/RRPioneer-UltraLightCondensed.woff';
import RRPioneerUltraLightCondensedWoff2 from './fonts/RRPioneer-UltraLightCondensed.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: 'RR Pioneer';
    src: url(${RRPioneerItalicWoff2}) format('woff2'), url(${RRPioneerItalicWoff}) format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'RR Pioneer';
    src: url(${RRPioneerMediumItalicWoff2}) format('woff2'), url(${RRPioneerMediumItalicWoff}) format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: 'RR Pioneer';
    src: url(${RRPioneerRegularWoff2}) format('woff2'), url(${RRPioneerRegularWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'RR Pioneer';
    src: url(${RRPioneerMediumWoff2}) format('woff2'), url(${RRPioneerMediumWoff}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'RR Pioneer UltraLight Condensed';
    src: url(${RRPioneerUltraLightCondensedWoff2}) format('woff2'), url(${RRPioneerUltraLightCondensedWoff}) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'RR Pioneer Light Condensed';
    src: url(${RRPioneerLightCondensedWoff2}) format('woff2'), url(${RRPioneerLightCondensedWoff}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'RR Pioneer';
    src: url(${RRPioneerBoldWoff2}) format('woff2'), url(${RRPioneerBoldWoff}) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'RR Pioneer';
    src: url(${RRPioneerBoldItalicWoff2}) format('woff2'), url(${RRPioneerBoldItalicWoff}) format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
  }
`;
