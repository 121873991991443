import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styled, { ThemeProvider } from 'styled-components';
import themeModify, { checkContrast } from '../../interface';
import Input from '../../interface/input';
import Button from '../../interface/button';
import Checkbox from '../../interface/checkbox';

import Portal from '@bloc/portal';
import Policy from './policy';

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  padding: 40px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.common.white};
  box-shadow: ${({ theme }) => theme.shadows[1]};
  overflow: auto;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    border-radius: 4px;
    width: 460px;
    height: auto;
  }

  & .input,
  & .button,
  & .dropdown {
    width: 100%;
  }

  & .input,
  & .dropdown {
    margin: 0.5em 0;
  }

  & .error {
    margin: 0;
    height: 1em;
    color: ${({ theme }) => theme.palette.error.main};
    text-align: center;
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
  }

  & .button {
    margin: 1.5em 0 0 0;
  }

  & h1 {
    margin: 0 0 0.5em 0;
    line-height: 1.05em;
    font-size: 30px;
    font-family: ${({ theme }) => theme.typography.h1.fontFamily};
    color: ${({ theme }) => checkContrast(theme.palette.primary.dark, theme.palette.common.white)};
    font-weight: 500;
  }

  & p {
    margin: 0;
    font-size: 16px;
    line-height: 1.2em;
    font-family: ${({ theme }) => theme.typography.subtitle1.fontFamily};
    color: ${({ theme }) => checkContrast(theme.palette.grey[700], theme.palette.common.white)};
    margin-bottom: 1.6em;
  }

  & .checkbox--policy {
    display: block;
    margin-top: 1em;

    & .checkbox__container {
      & h4 {
        margin: 0 0 0.5em 0;
        font-size: 18px;
        font-family: ${({ theme }) => theme.typography.h1.fontFamily};
        color: ${({ theme }) => checkContrast(theme.palette.primary.dark, theme.palette.grey[300])};
        font-weight: 500;
      }

      & p {
        margin: 0;
        font-size: 14px;
        line-height: 1.2em;
        font-family: ${({ theme }) => theme.typography.subtitle1.fontFamily};
        color: ${({ theme }) => checkContrast(theme.palette.grey[700], theme.palette.grey[300])};
      }
    }
  }
`;

/**
 *
 */
export default function AccessDialog({ theme, className, variant, error, onSubmit, parent, levels, active, ...props }) {
  const _theme = useMemo(() => themeModify(theme), [theme]);
  const [classList, setClassList] = useState(['login-box']);

  const [name, setName] = useState();
  const [company, setCompany] = useState();
  const [position, setPosition] = useState();
  const [email, setEmail] = useState();
  const [reason, setReason] = useState();

  const [hasConsent, setHasConsent] = useState(false);

  const [showPolicy, setShowPolicy] = useState(false);

  // set up classes/variants
  useEffect(() => {
    const _classList = ['login-box'];
    for (const c of className.split(' ')) {
      _classList.push(c);
    }

    for (const v of variant.split(' ')) {
      if (v) _classList.push(`variant--${v}`);
    }

    setClassList(_classList);
  }, [className, variant]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ name, company, position, email, reason });
  };

  return (
    active && (
      <Portal parent={parent.current} className="access-portal dialog-box">
        <ThemeProvider theme={_theme}>
          <StyledDiv classList={classList}>
            <form onSubmit={handleSubmit}>
              <h1>Request Access</h1>
              <p>Enter details below and submit an access request.</p>
              <Input label="Full Name" placeholder="Jane Doe" onChange={(e) => setName(e.target.value)} />
              <Input label="Company" placeholder="Roll-Royce" onChange={(e) => setCompany(e.target.value)} />
              <Input label="Position" placeholder="Researcher" onChange={(e) => setPosition(e.target.value)} />
              <Input
                label="Email"
                placeholder="email@email.com"
                type="email"
                onChange={(e) => {
                  if (e.target.checkValidity()) {
                    setEmail(e.target.value);
                  } else {
                    setEmail('');
                  }
                }}
              />
              <Input
                label="Reason for request..."
                placeholder="Just interested"
                onChange={(e) => setReason(e.target.value)}
              />
              <Checkbox
                onClick={() => setShowPolicy(true)}
                checked={hasConsent}
                className="checkbox--policy"
                variant="border button"
              >
                <h4>Agree to privacy policy?</h4>
                <p>By checking this box you consent for your data to be securely stored by us.</p>
              </Checkbox>
              <p className="error">{error}</p>
              <Button disabled={!hasConsent} onClick={handleSubmit}>
                Submit
              </Button>
            </form>
          </StyledDiv>
          <Policy
            open={showPolicy}
            locked
            onDeny={() => {
              setHasConsent(false);
              setShowPolicy(false);
            }}
            onConfirm={() => {
              setHasConsent(true);
              setShowPolicy(false);
            }}
          />
        </ThemeProvider>
      </Portal>
    )
  );
}

AccessDialog.propTypes = {
  /** theme object - pass in a modification to theme */
  theme: PropTypes.object,
  /** className string - classes to be added */
  className: PropTypes.string,
  /** variant string - variants to be added */
  variant: PropTypes.string,
  /** error string - error message */
  error: PropTypes.string,
  /** onSubmit function - callback function for form submit */
  onSubmit: PropTypes.func,
  /** levels array - list of all available levels */
  levels: PropTypes.array,
};

AccessDialog.defaultProps = {
  theme: {},
  className: '',
  variant: '',
  onSubmit: () => {},
};
