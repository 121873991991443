import { Fragment, useState, useEffect, useRef } from 'react';

// External components
import Loader from 'react-loader-spinner';
import styled from 'styled-components';

// Internal components
import CookieBanner from './components/CookieBanner';
import LoginDialog from './components/LoginDialog';
import AccessDialog from './components/AccessDialog';
import GuestDialog from './components/GuestDialog';
import PasswordDialog from './components/PasswordDialog';
import AuthDialog from './components/AuthDialog';
import Portal from '@bloc/portal';

// Hooks
import useMediaQuery from '@bloc/usemediaquery';
import useLocalStorage from '@bloc/uselocalstorage';

// Interface components
import { theme, GlobalFonts, LightenDarkenColor, Opacity } from './interface';
import Modal from './components/Modal';
import ImportSVG from './components/ImportSVG';

const StyledDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  & .appbar {
    padding: 0 1.5em;
    height: 70px;
    background-color: ${theme.palette.primary.dark};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: ${theme.zIndex.appBar};
    overflow: hidden;

    & span {
      font-size: 13.33333px;
      line-height: 0;
      display: block;
      margin-right: 1em;
      padding: 0.5em;

      & > svg {
        fill: ${theme.palette.common.white};
        height: 35px;
        width: auto;
      }
    }
  }

  & .site-body {
    flex-grow: 1;
    overflow: auto;
    background-color: #3c3c3c;
    background-image: url(${({ bg }) => bg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 26px;

    @media screen and (min-width: ${theme.breakpoints.md}px) {
      flex-grow: 1;
      margin-bottom: initial;

      & .dialog-box {
        box-sizing: border-box;
        padding: 20px 0;
      }
    }

    & .dialog-box {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .policy-buttons {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0.4em 2.6em;
    z-index: ${theme.zIndex.tooltip + 1};
    background-color: ${Opacity(theme.palette.common.black, 70)};
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: space-evenly;

    @media screen and (min-width: ${theme.breakpoints.md}px) {
      background-color: ${Opacity(theme.palette.common.black, 30)};
      border-radius: 4px 0 0 0;
      padding: 1.2em 2.6em;
      width: auto;
    }

    & .policy-buttons__button {
      font-size: 12px;
      font-family: ${theme.typography.subtitle1.fontFamily};
      color: ${LightenDarkenColor(theme.palette.common.white, -15)};

      &:hover {
        color: ${theme.palette.common.white};
      }

      &:nth-child(2) {
        @media screen and (min-width: ${theme.breakpoints.md}px) {
          margin-left: 3em;
        }
      }
    }
  }
`;

const LoadingPanel = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${theme.palette.grey[700]};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${theme.zIndex.modal};
  opacity: 0;
  transition: opacity ${theme.transitions.duration.shorter} ${theme.transitions.easing.easeInOut};

  &.internal--visible {
    opacity: 1;
    transition-duration: 0ms;
  }
`;

/**
 * TODO:
 *
 *
 */

const urlParams = new URLSearchParams(window.location.search);

function App() {
  const [classList, setClassList] = useState(['loading-panel']);
  const [isLoading, setIsLoading] = useState(true);
  const [initComplete, setInitComplete] = useState(false);
  const [background, setBackground] = useState(null);
  const [guestAccess, setGuestAccess] = useState(true);
  const [guestName, setGuestName] = useState(null);
  const [targetURL, setTargetURL] = useState(null);
  const [adminEmail, setAdminEmail] = useState(null);

  const [title, setTitle] = useState();
  const [msg, setMsg] = useState();

  const [errorMSG, setErrorMSG] = useState(null);

  const [logoLoc, setLogoLoc] = useState();
  const [logoLocSM, setLogoLocSM] = useState();

  //handle modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalHeader, setModalHeader] = useState();
  const [modalContent, setModalContent] = useState();

  // handle login step
  const [step, setStep] = useState('');
  const siteBody = useRef(null);

  const [isMobile] = useMediaQuery(`screen and (max-width: ${theme.breakpoints.md - 1}px)`);
  const storage = useLocalStorage('session');

  // handle portal
  const [exists, setExists] = useState(true);
  const [isShown, setIsShown] = useState(false);
  const loader = useRef(null);

  // get data
  useEffect(() => {
    if (initComplete) return;
    setInitComplete(true);
    const _getData = async () => {
      const id = storage.get('portal_id') || 603;

      // if there is no portal_id redirect back to portal select
      if (!id) {
        window.open(process.env.REACT_APP_SITE_URL, '_self');

        return;
      }

      setIsLoading(true);
      const data = await fetch(`${process.env.REACT_APP_SITE_URL}/login-list?id=${id}`);

      // If server returns error redirect back to portal select
      if (data.status < 200 || data.status > 299) {
        storage.remove('portal_id');
        window.open(process.env.REACT_APP_SITE_URL, '_self');

        return;
      }

      const json = await data.json();

      // If json returns no results redirect back to portal select
      if (json.status === 'no results') {
        storage.remove('portal_id');
        window.open(process.env.REACT_APP_SITE_URL, '_self');

        return;
      }

      const { specific, ...defaults } = json;
      const specificValue = {};

      if (Array.isArray(specific))
        for (const key of Object.keys(specific[0] || {})) {
          const data = specific[0][key];
          if (data.length) specificValue[key] = data;
        }

      const modified = Object.assign({}, defaults, specificValue);

      setBackground(modified.bg);
      setGuestAccess(modified.guestaccess === 1);
      setGuestName(modified.guestuser);
      setLogoLoc(modified.loginlogo);
      setLogoLocSM(modified.loginlogosm);
      setTargetURL(urlParams.get('redirect') || modified.eventurl);
      setAdminEmail(modified.requestaccessadmin);

      setTitle(modified.logintitle);
      setMsg(modified.logindesc);

      setIsLoading(false);

      setStep(modified.twofactor ? 'login' : 'guest');
    };

    _getData();
  }, [initComplete, storage]);

  // handle loading data
  useEffect(() => {
    const { current: el } = loader;
    let timeout;

    const onTransitionEnd = () => {
      setExists(false);
    };

    if (isLoading) {
      setExists(true);
      timeout = window.setTimeout(() => {
        setIsShown(true);
      }, 10);
    } else {
      if (el) el.addEventListener('transitionend', onTransitionEnd, { once: true });
      setIsShown(false);
    }

    return () => {
      window.clearTimeout(timeout);
      if (el) el.removeEventListener('transitionend', onTransitionEnd);
    };
  }, [isLoading]);

  // set up loading-panel classes
  useEffect(() => {
    const _classList = ['loading-panel'];

    if (isShown) _classList.push('internal--visible');

    setClassList(_classList);
  }, [isShown]);

  /**
   * Take a username and password then try to login.
   *
   * @param {string} username
   * @param {string} password
   * @returns
   */
  const login = async ({ username, password }) => {
    const user = step === 'login' ? username : guestName;

    if (!user) {
      setErrorMSG('Please enter a username.');

      return;
    }

    if (!password) {
      setErrorMSG('Please enter a password.');

      return;
    }

    const data = await fetch(`${process.env.REACT_APP_SITE_URL}api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: user,
        password: password,
      }),
    });

    // If server returns error redirect back to portal select
    if (data.status < 200 || data.status > 299) {
      if (data.status === 401) {
        setErrorMSG('The password you entered is incorrect.');
      } else {
        setErrorMSG('Something went wrong!');
      }

      return;
    }

    setErrorMSG(null);

    if (step === 'login') {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}api/twofactorcode`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 404) {
          window.open(targetURL, '_self');
        } else {
          setStep('auth');
        }
      } catch (err) {
        window.open(targetURL, '_self');
      }
    } else {
      if (targetURL) window.open(targetURL, '_self');
    }
  };

  /**
   *
   * @param {*} param0
   * @returns
   */
  const auth = async ({ auth }) => {
    if (!auth) {
      setErrorMSG('Please enter a pass code.');

      return;
    }

    const data = await fetch(`${process.env.REACT_APP_SITE_URL}api/twofactorcode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        authcode: auth,
      }),
    });

    // If server returns error redirect back to portal select
    if (data.status < 200 || data.status > 299) {
      if (data.status === 401) {
        setErrorMSG('Incorrect pass code, please try again.');
      } else {
        setErrorMSG('Something went wrong!');
      }

      return;
    }

    const json = data.json();

    if (json.success === false) {
      setErrorMSG('Incorrect pass code, try again.');

      return;
    }

    if (targetURL) window.open(targetURL, '_self');
  };

  const requestAccess = async ({ name, company, position, email, reason }) => {
    if (!name || !company || !position || !email || !reason) {
      setErrorMSG('Please fill out the request form.');

      return;
    }

    setErrorMSG(null);

    const data = await fetch(`${process.env.REACT_APP_SITE_URL}/api/requestaccess`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fullname: name,
        company: company,
        position: position,
        email: email,
        adminemail: adminEmail,
        reason: reason,
      }),
    });

    // If server returns error redirect back to portal select
    if (data.status < 200 || data.status > 299) {
      setErrorMSG('Something went wrong!');

      return;
    }

    setStep('login');
    setModalHeader('Request Submitted!');
    setModalContent('Keep an eye on your emails as we will be in touch.');
    setModalOpen(true);
  };

  const requestPassword = async ({ email }) => {
    if (!email) {
      setErrorMSG('Please fill out the request form.');

      return;
    }

    setErrorMSG(null);

    const data = await fetch(`${process.env.REACT_APP_SITE_URL}/api/forgot-pass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `username=${email}&login_fp_service=true`,
    });

    // If server returns error redirect back to portal select
    if (data.status < 200 || data.status > 299) {
      setErrorMSG('Something went wrong!');

      return;
    }

    setStep('login');
    setModalHeader('Request Submitted!');
    setModalContent('Keep an eye on your emails as we will be in touch.');
    setModalOpen(true);
  };

  return (
    <Fragment>
      <GlobalFonts />
      <StyledDiv bg={background}>
        <div className="appbar">
          <ImportSVG src={isMobile ? logoLocSM : logoLoc} />
        </div>
        {(exists || isShown) && (
          <Portal>
            <LoadingPanel ref={loader} className={classList}>
              <Loader type="ThreeDots" color={theme.palette.common.white} height={120} width={120} />
            </LoadingPanel>
          </Portal>
        )}
        <div ref={siteBody} className="site-body">
          <LoginDialog
            onSubmit={login}
            title={title}
            message={msg}
            error={errorMSG}
            onRequest={() => {
              setStep('access');
              setErrorMSG(null);
            }}
            onForgot={() => {
              setStep('password');
              setErrorMSG(null);
            }}
            onGuest={() => {
              setStep('guest');
              setErrorMSG(null);
            }}
            guest={guestAccess}
            active={step === 'login'}
            parent={siteBody}
          />

          <GuestDialog
            onSubmit={login}
            title={title}
            message={msg}
            error={errorMSG}
            active={step === 'guest'}
            parent={siteBody}
          />

          <PasswordDialog onSubmit={requestPassword} error={errorMSG} active={step === 'password'} parent={siteBody} />

          <AccessDialog onSubmit={requestAccess} error={errorMSG} active={step === 'access'} parent={siteBody} />

          <AuthDialog active={step === 'auth'} parent={siteBody} error={errorMSG} onSubmit={auth} />
        </div>

        <div className="policy-buttons">
          <a
            href="https://www.rolls-royce.com/site-services/portal-2-power.aspx"
            target="_blank"
            rel="noreferrer"
            className="policy-buttons__button"
          >
            Data Privacy Policy
          </a>
          <a
            href="https://www.rolls-royce.com/site-services/use-of-cookies.aspx"
            target="_blank"
            rel="noreferrer"
            className="policy-buttons__button"
          >
            Cookie Policy
          </a>
        </div>
      </StyledDiv>

      <CookieBanner>
        We use cookies on our website to give you the most relevant experience by remembering your preferences and
        repeat visits. By clicking “Accept”, you consent to the use of ALL the cookies.
      </CookieBanner>

      <Modal header={modalHeader} open={modalOpen} onClose={() => setModalOpen(false)}>
        {modalContent}
      </Modal>
    </Fragment>
  );
}

export default App;
